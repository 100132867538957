var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDrawer)?_c('ui-component-drawer',{attrs:{"drawerTitle":_vm.$t('Accounts.ManageAccounts.DrawerAccountSummary.Main_Title'),"showDrawer":_vm.showDrawer,"onClickClose":_vm.onClickClose,"position":"right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('AccountCardItem',{staticClass:"has-margin-bottom-x2",attrs:{"account":_vm.account}}),(_vm.account.AccountGroups.length)?_c('div',{staticClass:"has-margin-bottom-x2"},[_c('div',{staticClass:"title is-5 has-text-weight-bold",domProps:{"innerHTML":_vm._s(_vm.$t('Accounts.EditAccountRights.Label_AccountGroups'))}}),_c('div',{staticClass:"tags"},_vm._l((_vm.account.AccountGroups),function(accountGroup,index){return _c('div',{key:index,staticClass:"tag is-info",domProps:{"innerHTML":_vm._s(accountGroup.Name)}})}),0)]):_vm._e(),_c('div',{staticClass:"title is-5 has-text-weight-bold",domProps:{"innerHTML":_vm._s(
        _vm.$t('Accounts.ManageAccounts.DrawerAccountSummary.Title_AccountMay', {
          name: _vm.account.FullName,
        })
      )}}),_c('table',{staticClass:"table is-fullwidth is-striped is-narrow"},[_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"has-text-centered",domProps:{"innerHTML":_vm._s(_vm.$t('Accounts.EditAccountRights.TableHeader_View'))}}),_c('th',{staticClass:"has-text-centered",domProps:{"innerHTML":_vm._s(_vm.$t('Accounts.EditAccountRights.TableHeader_Edit'))}}),_c('th',{staticClass:"has-text-centered",domProps:{"innerHTML":_vm._s(_vm.$t('Accounts.EditAccountRights.TableHeader_Delete'))}})])]),(_vm.roleSettings)?_c('tbody',_vm._l((_vm.roleSettings),function(roleSetting,key,index){return _c('tr',{key:`as_${index}`},[_c('td',[_vm._v(" "+_vm._s(_vm.$t( `Components.Accounts.EditAccountRights.Label_${key.slice( 0, -6 )}` ))+" ")]),_vm._l((4),function(i,index){return [(index)?_c('td',{key:`asg_${index}`,staticClass:"has-text-centered"},[_c('font-awesome-icon',{class:index <= roleSetting
                    ? 'has-text-success'
                    : 'has-text-danger',attrs:{"icon":['fas', index <= roleSetting ? 'check' : 'times']}})],1):_vm._e()]})],2)}),0):_vm._e()])]},proxy:true}],null,false,2824777125)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }