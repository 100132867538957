<template>
  <div>
    <ui-component-modal
      :modalTitle="$t('Accounts.EditAccountRights.Modal.Main_Title')"
      modalSize="large"
      :onClickCancel="onClickCancel"
      :onClickSave="update"
      :showModal="showModal"
      :hideSaveButton="false"
      :disableSaveButton="editModeDisabled || isSaving"
      :isSaving="isSaving"
      :isSavingSuccess="isSavingSuccess"
      :savingSuccessMessage="
        $t('Accounts.EditAccountRights.Modal.Message_SavingSuccess', {
          name: account.FullName,
        })
      "
      :isSavingError="isSavingError"
      :savingErrorMessage="
        $t('Accounts.EditAccountRights.Modal.Message_SavingError', {
          name: account.FullName,
        })
      "
      @closeModal="onClickCancel"
    >
      <template v-slot:content>
        <EditAccountRights
          ref="editAccountRight"
          :account="account"
          :editModeDisabled="editModeDisabled"
          :isOwnAccount="isOwnAccount"
          accountCardColumnClass="is-full"
        />
      </template>
    </ui-component-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import accountProvider from '@/providers/account'
const EditAccountRights = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/Accounts/EditAccountRights'
  )

export default {
  name: 'modal-account-right',

  components: {
    EditAccountRights,
  },
  props: {
    account: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),
    ...mapState('profileStore', ['profile']),

    isOwnAccount() {
      if (this.profile) {
        return this.account.ProfileId === this.profile.Id
      }
      return false
    },

    editModeDisabled() {
      return this.isOwnAccount || false
    },
  },

  methods: {
    // Update account rights
    update() {
      let self = this

      if (!this.isSaving) {
        this.isSaving = true

        let accountRoleRights = this.$refs.editAccountRight.roleSettings
        accountRoleRights.AccountGroupIds = this.$refs.editAccountRight.accountGroupIds
        accountRoleRights.LocationId = this.locationState.Id
        accountRoleRights.ProfileId = this.account.ProfileId

        accountProvider.methods
          .updateAccount(this.account.Id, accountRoleRights)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              setTimeout(() => {
                self.$emit('reloadList')
                self.onClickCancel()
              }, 2500)
            }
          })
          .catch((e) => {
            self.isSavingError = true

            let t = setTimeout(() => {
              self.isSavingError = false
              self.isSaving = false
              clearTimeout(t)
            }, 2500)
          })
          .finally(() => {})
      }
    },
  },
}
</script>
