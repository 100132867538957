<template>
  <ui-component-modal
    :modalTitle="$t('Accounts.ModalDeleteAccount.Main_Title')"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteAccount"
    :showModal="showModal"
    confirmButtonCssClass="is-danger"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    :disableSaveButton="!agreed || isSaving"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :savingSuccessMessage="
      $t('Accounts.ModalDeleteAccount.Message_SavingSuccess', {
        name: account.FullName,
      })
    "
    :isSavingError="isSavingError"
    :savingErrorMessage="
      $t('Accounts.ModalDeleteAccount.Message_SavingError', {
        name: account.FullName,
      })
    "
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div
        class="has-margin-bottom is-size-5 has-text-centered"
        v-html="
          $t('Accounts.ModalDeleteAccount.Text_RemoveAccount', {
            name: account.FullName,
          })
        "
      ></div>
      <div class="has-text-centered">
        <BaseCheckbox
          v-model="agreed"
          class="has-text-left"
          :label="$t('Accounts.ModalDeleteAccount.Text_YesSure')"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapMutations } from 'vuex'
import accountProvider from '@/providers/account'
const BaseCheckbox = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/UI/Form/BaseCheckbox'
  )

export default {
  name: 'modal-delete-account',
  props: {
    account: {
      type: Object,
      required: true,
    },
    accountGroup: {
      default: null,
      type: Object,
    },

    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  components: {
    BaseCheckbox,
  },

  data() {
    return {
      agreed: false,
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
    }
  },

  methods: {
    ...mapMutations('accountStore', ['setAccountGroups']),

    deleteAccount() {
      let self = this
      if (!this.isSaving) {
        this.isSaving = true
        accountProvider.methods
          .deleteAccount(this.account.Id)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              self.$emit('reloadList')
              let t = setTimeout(() => {
                self.onClickCancel()
                clearTimeout(t)
              }, 2500)
            }
          })
          .catch(() => {
            self.isSavingError = true

            let t = setTimeout(() => {
              self.isSavingError = false
              self.isSaving = false
              clearTimeout(t)
            }, 2500)
          })
          .finally(() => {})
      }
    },
  },
}
</script>
