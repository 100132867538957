<template>
  <ui-component-modal
    :modalTitle="'Request location access'"
    :onClickCancel="onClickCancel"
    :onClickSave="addAccount"
    :showModal="showModal"
    :disableSaveButton="isSaving"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :savingSuccessMessage="savingSuccessMessage"
    :isSavingError="isSavingError"
    :savingErrorMessage="savingErrorMessage"
    :buttonLabelConfirm="'Send request'"
    modalSize="large"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <transition name="fade" mode="out-in">
        <div key="setRights">
          <EditAccountRights
            ref="editAccountRight"
            :editModeDisabled="editModeDisabled"
            :account="selectedAccount"
          />
        </div>
      </transition>
    </template>
  </ui-component-modal>
</template>

<script>
const EditAccountRights = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/Accounts/EditAccountRights'
  )
// const BaseFieldTemplate = () =>
//   import(
//     /* webpackChunkName: "settings-accounts" */ '@/components/UI/Form/BaseFieldTemplate'
//   )
// const Register = () =>
//   import(
//     /* webpackChunkName: "settings-accounts" */ '@/components/AccountControls/Register'
//   )
// const Message = () =>
//   import(/* webpackChunkName: "settings-accounts" */ '@/components/UI/Message')
// const Pagination = () =>
//   import(
//     /* webpackChunkName: "settings-accounts" */ '@/components/UI/Pagination'
//   )

import accountProvider from '@/providers/account'
import { mapState } from 'vuex'

export default {
  name: 'modal-add-account',

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  components: {
    //BaseFieldTemplate,
    EditAccountRights,
    //Message,
    //Pagination,
    //Register,
  },

  data() {
    return {
      isSavingSuccess: false,
      isSavingError: false,
      isSearching: false,
      isSaving: false,
      itemsPerPage: 10,
      noResult: false,
      morePages: 0,
      page: 1,
      selectedAccount: null,
      showAccountRights: false,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),
    ...mapState('profileStore', ['profile']),

    savingSuccessMessage() {
      return this.selectedAccount
        ? this.$t('Accounts.ModalAddAccount.Message_SavingSuccess', {
            name: this.selectedAccount.FullName,
          })
        : ''
    },
    savingErrorMessage() {
      return this.selectedAccount
        ? this.$t('Accounts.ModalAddAccount.Message_SavingError', {
            name: this.selectedAccount.FullName,
          })
        : ''
    },

    editModeDisabled() {
      return false
    },
  },

  created() {
    this.selectedAccount = this.profile
  },

  beforeDestroy() {},

  methods: {
    // Save new account
    addAccount() {
      let self = this

      if (!this.isSaving) {
        this.isSaving = true
        let accountRoleRights = this.$refs.editAccountRight.roleSettings
        accountRoleRights.AccountGroupIds = []
        accountRoleRights.LocationId = this.locationState.Id
        accountRoleRights.ProfileId = this.profile.Id
        accountRoleRights.Status = 'Request'

        accountProvider.methods
          .saveAccount(accountRoleRights)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              self.$emit('reloadList')

              let t = setTimeout(() => {
                self.onClickCancel()
                clearTimeout(t)
              }, 2500)
            }
          })
          .catch((e) => {
            self.isSavingError = true

            let t = setTimeout(() => {
              self.isSavingError = false
              self.isSaving = false
              clearTimeout(t)
            }, 2500)
          })
          .finally(() => {})
      }
    },
  },
}
</script>
