<template>
  <ui-component-drawer
    v-if="showDrawer"
    :drawerTitle="$t('Accounts.ManageAccounts.DrawerAccountSummary.Main_Title')"
    :showDrawer="showDrawer"
    :onClickClose="onClickClose"
    position="right"
  >
    <template v-slot:content>
      <AccountCardItem class="has-margin-bottom-x2" :account="account" />

      <div v-if="account.AccountGroups.length" class="has-margin-bottom-x2">
        <div
          class="title is-5 has-text-weight-bold"
          v-html="$t('Accounts.EditAccountRights.Label_AccountGroups')"
        ></div>
        <div class="tags">
          <div
            class="tag is-info"
            v-for="(accountGroup, index) in account.AccountGroups"
            :key="index"
            v-html="accountGroup.Name"
          ></div>
        </div>
      </div>

      <div
        class="title is-5 has-text-weight-bold"
        v-html="
          $t('Accounts.ManageAccounts.DrawerAccountSummary.Title_AccountMay', {
            name: account.FullName,
          })
        "
      ></div>
      <table class="table is-fullwidth is-striped is-narrow">
        <thead>
          <tr>
            <th></th>
            <th
              class="has-text-centered"
              v-html="$t('Accounts.EditAccountRights.TableHeader_View')"
            ></th>
            <th
              class="has-text-centered"
              v-html="$t('Accounts.EditAccountRights.TableHeader_Edit')"
            ></th>
            <th
              class="has-text-centered"
              v-html="$t('Accounts.EditAccountRights.TableHeader_Delete')"
            ></th>
          </tr>
        </thead>
        <tbody v-if="roleSettings">
          <tr
            v-for="(roleSetting, key, index) in roleSettings"
            :key="`as_${index}`"
          >
            <td>
              {{
                $t(
                  `Components.Accounts.EditAccountRights.Label_${key.slice(
                    0,
                    -6
                  )}`
                )
              }}
            </td>
            <template v-for="(i, index) in 4">
              <td v-if="index" :key="`asg_${index}`" class="has-text-centered">
                <font-awesome-icon
                  :icon="['fas', index <= roleSetting ? 'check' : 'times']"
                  :class="
                    index <= roleSetting
                      ? 'has-text-success'
                      : 'has-text-danger'
                  "
                />
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-drawer>
</template>

<script>
const AccountCardItem = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/Accounts/AccountCardItem'
  )

export default {
  name: 'drawer-account-summary',

  props: {
    account: {
      type: Object,
      required: true,
    },

    showDrawer: {
      type: Boolean,
      default: false,
    },

    onClickClose: {
      type: Function,
      default: () => {},
    },
  },

  components: {
    AccountCardItem,
  },

  data() {
    return {
      roleSettings: null,
      rightStateLabels: ['None', 'View', 'Edit', 'Full'],
    }
  },

  created() {
    this.roleSettings = this.buildRightsSettings()
  },

  methods: {
    buildRightsSettings() {
      return {
        AccountRoleId:
          this.rightStateLabels.indexOf(this.account.AccountLevel) || 0,
        InvoiceRoleId:
          this.rightStateLabels.indexOf(this.account.InvoiceLevel) || 0,
        LocationRoleId:
          this.rightStateLabels.indexOf(this.account.LocationLevel) || 0,
        OptionRoleId:
          this.rightStateLabels.indexOf(this.account.OptionLevel) || 0,
        PriceRoleId:
          this.rightStateLabels.indexOf(this.account.PriceLevel) || 0,
        ReportRoleId:
          this.rightStateLabels.indexOf(this.account.ReportLevel) || 0,
        ReservationRoleId:
          this.rightStateLabels.indexOf(this.account.ReservationLevel) || 0,
        SpaceRoleId:
          this.rightStateLabels.indexOf(this.account.SpaceLevel) || 0,
        VoucherRoleId:
          this.rightStateLabels.indexOf(this.account.VoucherLevel) || 0,
      }
    },
  },
}
</script>
