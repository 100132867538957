<template>
  <ui-component-modal
    :modalTitle="$t('Accounts.ModalAddAccount.Main_Title')"
    :onClickCancel="onClickCancel"
    :onClickSave="addAccount"
    :showModal="showModal"
    :disableSaveButton="!selectedAccount || isSaving"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :savingSuccessMessage="savingSuccessMessage"
    :isSavingError="isSavingError"
    :savingErrorMessage="savingErrorMessage"
    :buttonLabelConfirm="$t('Accounts.ModalAddAccount.Button_AddAccount')"
    modalSize="large"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <transition name="fade" mode="out-in">
        <div key="search" v-if="viewType === viewTypes.search">
          <div class="has-margin-bottom">
            <div class="columns is-multiline">
              <div class="column column-search is-half">
                <BaseFieldTemplate
                  :label="$t('Accounts.ModalAddAccount.Label_Search')"
                >
                  <template v-slot:fields>
                    <SearchAccount
                      @startSearch="(val) => search(val)"
                      :searchTerm="searchQuery"
                      :isSearching="isSearching"
                    ></SearchAccount>
                  </template>
                </BaseFieldTemplate>
              </div>

              <div v-if="noResult" class="column is-full">
                <Message
                  class="has-margin-bottom-x2"
                  :message="$t('Accounts.ModalAddAccount.Message_NoResult')"
                />
                <a
                  @click="viewType = viewTypes.create"
                  class="button is-success"
                  v-html="$t('Accounts.ModalAddAccount.Button_CreateAccount')"
                ></a>
              </div>
              <div class="column is-full">
                <div class="columns is-multiline">
                  <div
                    v-for="account in accounts"
                    :key="`a_${account.Id}_${Number(isSearching)}`"
                    class="column is-half"
                  >
                    <AccountCardItem
                      class="account"
                      :showPlaceHolder="isSearching"
                      :account="account"
                      imageSize="is-48x48"
                    >
                      <a
                        @click="chooseProfile(account)"
                        class="button is-success"
                        v-html="$t('Form.Control.Choose')"
                      >
                      </a>
                    </AccountCardItem>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            v-if="accounts.length"
            :currentPage="paginationPage"
            :morePages="morePages"
            @pageSelected="getPageData"
            :disableControls="isSearching"
          />
        </div>
        <div
          key="setRights"
          v-if="viewType === viewTypes.rights && selectedAccount"
        >
          <EditAccountRights
            ref="editAccountRight"
            :editModeDisabled="editModeDisabled"
            :account="selectedAccount"
          >
            <template v-slot:accountCardItemActionSlot
              ><a @click="undoSelectedAccount" class="is-small has-text-danger">
                <span class="icon">
                  <font-awesome-icon :icon="['fas', 'trash-alt']" />
                </span>
              </a>
            </template>
          </EditAccountRights>
        </div>
        <div key="register" v-if="viewType === viewTypes.create">
          <div
            class="title is-5 has-text-weight-bold"
            v-html="$t('Accounts.ModalAddAccount.Header_CreateAccount')"
          ></div>
          <Message
            :message="$t('Accounts.ModalAddAccount.Message_RegisterAccount')"
          />
          <Register
            :labelbuttonRegister="
              $t('Accounts.ModalAddAccount.Button_CreateAccountSetRights')
            "
            :backFunc="
              () => {
                viewType = viewTypes.search
              }
            "
            :channelId="channel.Id"
            :locationId="0"
            :isBackend="true"
            :hidePasswordField="false"
            @nextAction="
              (val) => {
                chooseProfile(val)
              }
            "
          />
        </div>
      </transition>
    </template>
  </ui-component-modal>
</template>

<script>
const SearchAccount = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/Accounts/SearchAccount'
  )
const AccountCardItem = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/Accounts/AccountCardItem'
  )
const EditAccountRights = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/Accounts/EditAccountRights'
  )
const BaseFieldTemplate = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/UI/Form/BaseFieldTemplate'
  )
const Register = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/AccountControls/Register'
  )
const Message = () =>
  import(/* webpackChunkName: "settings-accounts" */ '@/components/UI/Message')
const Pagination = () =>
  import(
    /* webpackChunkName: "settings-accounts" */ '@/components/UI/Pagination'
  )

import accountProvider from '@/providers/account'
import profileProvider from '@/providers/profile'
import { mapState } from 'vuex'

export default {
  name: 'modal-add-account',

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  components: {
    BaseFieldTemplate,
    SearchAccount,
    AccountCardItem,
    EditAccountRights,
    Message,
    Pagination,
    Register,
  },

  data() {
    return {
      accounts: [],
      isSavingSuccess: false,
      isSavingError: false,
      isSearching: false,
      isSaving: false,
      itemsPerPage: 10,
      noResult: false,
      morePages: 0,
      page: 1,
      searchQuery: '',
      selectedAccount: null,
      showAccountRights: false,
      viewType: '',
      viewTypes: {
        create: 'create account',
        search: 'search accunts',
        rights: 'set account rights',
      },
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),
    ...mapState('locationStore', ['locationState']),

    savingSuccessMessage() {
      return this.selectedAccount
        ? this.$t('Accounts.ModalAddAccount.Message_SavingSuccess', {
            name: this.selectedAccount.FullName,
          })
        : ''
    },
    savingErrorMessage() {
      return this.selectedAccount
        ? this.$t('Accounts.ModalAddAccount.Message_SavingError', {
            name: this.selectedAccount.FullName,
          })
        : ''
    },

    editModeDisabled() {
      return false
    },
  },

  created() {
    this.viewType = this.viewTypes.search
  },

  beforeDestroy() {
    profileProvider.methods.cancelGetProfiles()
  },

  methods: {
    chooseProfile(account) {
      this.selectedAccount = account
      this.viewType = this.viewTypes.rights
      profileProvider.methods.cancelGetProfiles()
    },

    undoSelectedAccount() {
      this.selectedAccount = null
      this.viewType = this.viewTypes.search
    },

    goToRights() {
      this.showAccountRights = true
    },
    hideModal() {
      this.showAccountRights = false
    },

    search(searchQuery = '') {
      if (searchQuery !== '') {
        this.searchQuery = searchQuery
        this.page = 1
        this.getProfiles()
      }
    },

    getPageData(page) {
      this.page = page
      this.getProfiles()
    },

    getProfiles() {
      if (this.searchQuery) {
        let self = this
        this.noResult = false
        this.isSearching = true

        profileProvider.methods
          .getProfiles(
            this.searchQuery,
            this.page,
            this.itemsPerPage,
            this.channel.Id,
            true
          )
          .then((response) => {
            if (response.data.Results.length) {
              self.paginationPage = self.page
              self.accounts = response.data.Results
              self.morePages = response.data.MorePages
            } else {
              self.noResult = true
              self.accounts = []
              self.morePages = 0
            }
          })
          .catch((e) => {})
          .finally(() => {
            self.isSearching = false
          })
      }
    },

    // Save new account
    addAccount() {
      let self = this

      if (!this.isSaving) {
        this.isSaving = true
        let accountRoleRights = this.$refs.editAccountRight.roleSettings
        accountRoleRights.AccountGroupIds = []
        accountRoleRights.LocationId = this.locationState.Id
        accountRoleRights.ProfileId = this.selectedAccount.Id

        accountProvider.methods
          .saveAccount(accountRoleRights)
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true
              self.$emit('reloadList')

              let t = setTimeout(() => {
                self.onClickCancel()
                clearTimeout(t)
              }, 2500)
            }
          })
          .catch((e) => {
            self.isSavingError = true

            let t = setTimeout(() => {
              self.isSavingError = false
              self.isSaving = false
              clearTimeout(t)
            }, 2500)
          })
          .finally(() => {})
      }
    },
  },
}
</script>
