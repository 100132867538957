<template>
  <div>
    <Message
      class="is-danger has-margin-bottom"
      :message="errorMessage"
      v-if="errorMessage"
    />
    <BaseInputField
      v-model="profile.firstName"
      :label="$t(`AccountControls.Register.Label_FirstName`)"
      :labelForInput="labelForInput"
      :error="firstNameErrors.toString()"
      @input="delayTouch($v.profile.firstName)"
      @blur="$v.profile.firstName.$touch()"
      required
    />
    <BaseInputField
      v-model="profile.lastName"
      :label="$t(`AccountControls.Register.Label_LastName`)"
      :labelForInput="labelForInput"
      :error="lastNameErrors.toString()"
      @input="$v.profile.lastName.$touch()"
      @blur="$v.profile.lastName.$touch()"
      required
    />
    <BaseInputField
      v-model="profile.email"
      :label="$t(`AccountControls.Register.Label_Email`)"
      :labelForInput="labelForInput"
      :error="emailErrors.toString()"
      @input="delayTouch($v.profile.email)"
      @blur="$v.profile.email.$touch()"
      required
    />
    <ui-base-input-field
      v-if="!hidePasswordField"
      v-model="profile.password"
      :labelForInput="labelForInput"
      :error="passwordErrors"
      :label="$t('AccountControls.Register.Label_Password')"
      fieldType="password"
      @input="$v.profile.password.$touch()"
      @blur="$v.profile.password.$touch()"
      required
    />

    <BaseFieldTemplate :labelForInput="labelForInput">
      <template v-slot:fields>
        <div class="level">
          <div class="level-left">
            <a class="has-icon" @click="backFunc">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'angle-left']" />
              </span>
              <span class="has-icon" v-html="$t('Form.Control.Back')"></span>
            </a>
          </div>
          <div class="level-right">
            <button
              class="button is-success"
              @click="save"
              :class="{ 'is-loading': isSaving || isCheckin }"
              :disabled="Boolean($v.$error) || isCheckin"
              v-html="labelbuttonRegister"
            ></button>
          </div>
        </div>
      </template>
    </BaseFieldTemplate>
  </div>
</template>

<script>
const BaseFieldTemplate = () =>
  import(
    /* webpackChunkName: "AccountControls" */ '@/components/UI/Form/BaseFieldTemplate'
  )
const BaseInputField = () =>
  import(
    /* webpackChunkName: "AccountControls" */ '@/components/UI/Form/BaseInputField'
  )
const Message = () =>
  import(/* webpackChunkName: "AccountControls" */ '@/components/UI/Message')
import profileProvider from '@/providers/profile'
import termsProvider from '@/providers/terms'

import { required, email, minLength } from 'vuelidate/lib/validators'
import encryptString from '@/mixins/encryptString'

const touchMap = new WeakMap()

export default {
  name: 'register',

  props: {
    channelId: {
      type: Number,
      default: 0,
      required: true,
    },

    locationId: {
      type: Number,
      default: 0,
    },

    labelForInput: {
      type: Boolean,
      default: true,
    },

    hidePasswordField: {
      type: Boolean,
      default: false,
    },

    isBackend: {
      type: Boolean,
      default: false,
    },

    labelbuttonRegister: {
      type: String,
      default: function() {
        return this.$t(`AccountControls.Register.Button_Register`)
      },
    },
    backFunc: {
      type: Function,
      default: () => {},
    },
  },

  components: {
    BaseFieldTemplate,
    BaseInputField,
    Message,
  },

  mixins: [encryptString],

  data() {
    return {
      firstName: '',
      isCheckin: false,
      isSaving: false,
      errorMessage: '',
      profileCheckTimeout: null,
      terms: { Id: 0 },
      profile: {
        firstName: '',
        lastName: '',
        email: '',
        password: this.hidePasswordField
          ? `${new Date().getTime()}D&@!${new Date().getTime()}`
          : '',
      },
    }
  },

  /**
   * Form validation rules
   */
  validations() {
    let output = {
      firstName: {
        required,
        minLength: minLength(2),
      },
      lastName: {
        required,
        minLength: minLength(2),
      },
      email: {
        required,
        email,
      },
    }
    if (!this.hidePasswordField) {
      output.password = {
        required,
        minLength: minLength(6),
      }
    }

    return {
      profile: output,
    }
  },

  computed: {
    firstNameErrors() {
      const errors = []
      if (!this.$v.profile.firstName.$dirty) return errors
      !this.$v.profile.firstName.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      !this.$v.profile.firstName.minLength &&
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 2 }))
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.profile.lastName.$dirty) return errors
      !this.$v.profile.lastName.required &&
        errors.push(this.$t('Form.InputErrors.Required'))
      return errors
    },
    emailErrors() {
      const errors = []
      if (!this.$v.profile.email.$dirty) return errors
      if (!this.$v.profile.email.required) {
        errors.push(this.$t('Form.InputErrors.Required'))
      } else if (!this.$v.profile.email.email) {
        errors.push(this.$t('Form.InputErrors.InvalidEmail'))
      }
      return errors
    },

    passwordErrors() {
      const errors = []
      if (!this.$v.profile.password.$dirty) return errors
      if (!this.$v.profile.password.required) {
        errors.push(
          this.$t('Form.InputErrors.RequiredField', {
            fieldLabel: this.$t('AccountControls.Register.Label_Password'),
          })
        )
      }
      if (!this.$v.profile.password.minLength) {
        errors.push(this.$t('Form.InputErrors.minLength', { minLength: 6 }))
      }
      if (!/(?=.*[A-Z])/.test(this.profile.password)) {
        errors.push(this.$t('Form.InputErrors.uppercaseCharacter'))
      }
      if (!/(?=.*\d)/.test(this.profile.password)) {
        errors.push(this.$t('Form.InputErrors.numberCharacter'))
      }
      return errors
    },
  },

  created() {
    this.getLatestTerms()
  },

  methods: {
    async getLatestTerms() {
      let self = this
      await termsProvider.methods
        .getLatestTerms({
          channelId: this.channelId,
          locale: this.$i18n.locale,
        })
        .then((response) => {
          self.terms = response.data
        })
        .catch((error) => {})
    },

    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },

    async save() {
      let self = this
      this.errorMessage = ''
      if (!this.isSaving) {
        this.isSaving = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.isSaving = false
        } else {
          let encryptedPassword = self.encryptPassword(this.profile.password)

          await profileProvider.methods
            .createProfile({
              FirstName: this.profile.firstName,
              LastName: this.profile.lastName,
              Email: this.profile.email,
              Password: encryptedPassword,
              ChannelId: this.channelId,
              LocationId: this.locationId,
              TermsId: this.terms.Id,
              IsBackend: this.isBackend,
            })
            .then((response) => {
              if (response.data.StatusCode === 200) {
                self.$emit('nextAction', response.data)
              } else {
                self.errorMessage = self.$t(
                  'AccountControls.Register.Message_ErrorCode501'
                )
              }
            })
            .catch((e) => {
              self.errorMessage = self.$t(
                'AccountControls.Register.Message_ErrorCode500'
              )
              self.isSaving = false
            })
        }
      }
    },
  },
}
</script>
