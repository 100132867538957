<template>
  <div class="input-search">
    <div class="field has-addons">
      <div class="control">
        <input
          type="search"
          v-model="mSearchTerm"
          v-on:keyup.enter="startSearch"
          class="input"
          :placeholder="placeHolder"
        />
      </div>

      <div class="control">
        <button
          @click="startSearch"
          class="button is-success"
          :class="{ 'is-loading': isSearching }"
          :disabled="isSearching"
        >
          <span class="icon">
            <font-awesome-icon
              v-show="!isSearching"
              :icon="['fas', 'search']"
              style="color:white"
            />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isSearching: {
      default: false,
      type: Boolean,
    },

    placeHolder: {
      type: String,
      default: function() {
        return this.$t('Accounts.ModalAddAccount.Placeholder_NameEmail')
      },
    },

    searchTerm: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      mSearchTerm: this.searchTerm,
    }
  },

  methods: {
    startSearch() {
      if (!this.isSearching) {
        this.$emit('startSearch', this.mSearchTerm.trim())
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.field {
  .control:first-child {
    width: 100%;
  }
}
</style>
