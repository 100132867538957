<template>
  <div class="edit-account-rights">
    <div class="columns">
      <div class="column" :class="accountCardColumnClass">
        <AccountCardItem :account="account" imageSize="is-48x48">
          <slot name="accountCardItemActionSlot"></slot>
        </AccountCardItem>
      </div>
    </div>

    <div class="accountRights has-margin-top">
      <div
        class="title is-5 has-text-weight-bold"
        v-html="
          $t('Components.Accounts.EditAccountRights.Title_AccountRights', {
            name: account.FullName,
          })
        "
      ></div>
      <Message v-if="isOwnAccount" class="is-warning">
        <template v-slot:content>
          {{
            $t('Components.Accounts.EditAccountRights.Message_NotEditOwnRights')
          }}</template
        >
      </Message>
      <table class="table is-fullwidth is-striped">
        <thead>
          <tr>
            <th></th>
            <th
              class="has-text-centered"
              v-html="
                $t('Components.Accounts.EditAccountRights.TableHeader_None')
              "
            ></th>
            <th
              class="has-text-centered"
              v-html="
                $t('Components.Accounts.EditAccountRights.TableHeader_View')
              "
            ></th>
            <th
              class="has-text-centered"
              v-html="
                $t('Components.Accounts.EditAccountRights.TableHeader_Edit')
              "
            ></th>
            <th
              class="has-text-centered"
              v-html="
                $t('Components.Accounts.EditAccountRights.TableHeader_Delete')
              "
            ></th>
          </tr>
        </thead>
        <tbody v-if="roleSettings">
          <tr
            v-for="(roleSetting, key, index) in roleSettings"
            :key="`as_${index}`"
          >
            <td
              v-text="
                $t(
                  `Components.Accounts.EditAccountRights.Label_${key.slice(
                    0,
                    -6
                  )}`
                )
              "
            ></td>
            <td
              v-for="(i, idx) in 4"
              :key="`asg_${i}`"
              class="has-text-centered"
            >
              <input
                type="radio"
                :value="rightStateLabels[idx]"
                v-model="roleSettings[key]"
                :disabled="editModeDisabled"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
const AccountCardItem = () => import('@/components/Accounts/AccountCardItem')
//const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')
const Message = () => import('@/components/UI/Message')

import accountProvider from '@/providers/account'

export default {
  name: 'edit-account-rights',

  props: {
    account: {
      type: Object,
      required: true,
    },
    accountCardColumnClass: {
      type: String,
      default: 'is-half',
    },

    isOwnAccount: {
      type: Boolean,
      default: false,
    },

    editModeDisabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AccountCardItem,
    //BaseCheckbox,
    Message,
  },

  data() {
    return {
      accountGroupIds: [],
      roleSettings: null,
      rightStateLabels: ['None', 'View', 'Edit', 'Full'],
    }
  },

  computed: {
    ...mapState('accountStore', ['accountGroups']),
  },

  created() {
    this.roleSettings = this.buildRightsSettings()
    if (this.accountGroups.length === 0) {
      this.getAccountGroups()
    }
  },

  methods: {
    buildRightsSettings() {
      return {
        AccountRoleId: this.account.AccountLevel || 'None',
        InvoiceRoleId: this.account.InvoiceLevel || 'None',
        LocationRoleId: this.account.LocationLevel || 'None',
        OptionRoleId: this.account.OptionLevel || 'None',
        PriceRoleId: this.account.PriceLevel || 'None',
        ReportRoleId: this.account.ReportLevel || 'None',
        ReservationRoleId: this.account.ReservationLevel || 'None',
        SpaceRoleId: this.account.SpaceLevel || 'None',
        VoucherRoleId: this.account.VoucherLevel || 'None',
      }
    },

    getAccountGroups() {
      let self = this

      accountProvider.methods
        .getLocationAccountGroups(self.locationState.Id)
        .then((response) => {
          if (response.status === 200) {
            self.setAccountGroups(response.data)
          }
        })
    },
  },
}
</script>
