<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      :key="`loading${prefix}`"
      v-if="showPlaceHolder || account === null"
      class="card"
    >
      <div class="card-content">
        <div class="media">
          <figure class="media-left">
            <p
              class="image"
              :class="imageSize"
            >
              <img
                class="is-rounded"
                src="@/assets/images/spacer1_1.gif"
              >
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <TextPlaceholder
                class="is-inline-flex"
                :isLoading="true"
                :numberOfItems="2"
              /><br />
              <TextPlaceholder
                class="is-inline-flex"
                :isLoading="true"
                :numberOfItems="1"
              />
            </div>
          </div>
          <div class="media-right">
            <TextPlaceholder
              class="is-inline-flex"
              :isLoading="true"
              :fixSize="18"
              :numberOfItems="1"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      :key="`detail${prefix}`"
      class="card"
    >
      <div class="card-content">
        <div class="media">
          <figure class="media-left">
            <p
              class="image"
              :class="imageSize"
            >
              <img
                class="is-rounded"
                src="@/assets/images/spacer1_1.gif"
                :style="{ backgroundImage: `url('${image}')` }"
              >
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              {{account.FullName}}<br />
              {{account.Email}}
            </div>
          </div>
          <div class="media-right">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
const TextPlaceholder = () => import(/* webpackChunkName: "settings-accounts" */ '@/components/UI/TextPlaceholder')
export default {
  name: 'account-card-item',

  props: {
    account: {
      type: Object,
      default: null
    },
    showPlaceHolder: {
      type: Boolean,
      default: false
    },
    imageSize: {
      type: String,
      default: 'is-64x64'
    }
  },

  components: {
    TextPlaceholder
  },

  data() {
    return {
      prefix: new Date().getTime()
    }
  },

  computed: {
    image() {
      if (this.account && this.account.image) {
        return this.$options.filters.getProfileImage(this.account.Image, this.account.ProfileId, 84)
      }
      return require('@/assets/images/noImage.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  word-break: break-all;
}
</style>