var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ui-component-modal',{attrs:{"modalTitle":_vm.$t('Accounts.ModalDeleteAccount.Main_Title'),"onClickCancel":_vm.onClickCancel,"onClickSave":_vm.deleteAccount,"showModal":_vm.showModal,"confirmButtonCssClass":"is-danger","buttonLabelConfirm":_vm.$t('Form.Control.Delete'),"disableSaveButton":!_vm.agreed || _vm.isSaving,"hideFooterButtons":_vm.isSavingError || _vm.isSavingSuccess,"isSaving":_vm.isSaving,"isSavingSuccess":_vm.isSavingSuccess,"savingSuccessMessage":_vm.$t('Accounts.ModalDeleteAccount.Message_SavingSuccess', {
      name: _vm.account.FullName,
    }),"isSavingError":_vm.isSavingError,"savingErrorMessage":_vm.$t('Accounts.ModalDeleteAccount.Message_SavingError', {
      name: _vm.account.FullName,
    })},on:{"closeModal":_vm.onClickCancel},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"has-margin-bottom is-size-5 has-text-centered",domProps:{"innerHTML":_vm._s(
        _vm.$t('Accounts.ModalDeleteAccount.Text_RemoveAccount', {
          name: _vm.account.FullName,
        })
      )}}),_c('div',{staticClass:"has-text-centered"},[_c('BaseCheckbox',{staticClass:"has-text-left",attrs:{"label":_vm.$t('Accounts.ModalDeleteAccount.Text_YesSure')},model:{value:(_vm.agreed),callback:function ($$v) {_vm.agreed=$$v},expression:"agreed"}})],1)]},proxy:true}])},[_c('template',{slot:"modalTitle"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }