var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"edit-account-rights"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",class:_vm.accountCardColumnClass},[_c('AccountCardItem',{attrs:{"account":_vm.account,"imageSize":"is-48x48"}},[_vm._t("accountCardItemActionSlot")],2)],1)]),_c('div',{staticClass:"accountRights has-margin-top"},[_c('div',{staticClass:"title is-5 has-text-weight-bold",domProps:{"innerHTML":_vm._s(
        _vm.$t('Components.Accounts.EditAccountRights.Title_AccountRights', {
          name: _vm.account.FullName,
        })
      )}}),(_vm.isOwnAccount)?_c('Message',{staticClass:"is-warning",scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Components.Accounts.EditAccountRights.Message_NotEditOwnRights')))]},proxy:true}],null,false,4067476021)}):_vm._e(),_c('table',{staticClass:"table is-fullwidth is-striped"},[_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"has-text-centered",domProps:{"innerHTML":_vm._s(
              _vm.$t('Components.Accounts.EditAccountRights.TableHeader_None')
            )}}),_c('th',{staticClass:"has-text-centered",domProps:{"innerHTML":_vm._s(
              _vm.$t('Components.Accounts.EditAccountRights.TableHeader_View')
            )}}),_c('th',{staticClass:"has-text-centered",domProps:{"innerHTML":_vm._s(
              _vm.$t('Components.Accounts.EditAccountRights.TableHeader_Edit')
            )}}),_c('th',{staticClass:"has-text-centered",domProps:{"innerHTML":_vm._s(
              _vm.$t('Components.Accounts.EditAccountRights.TableHeader_Delete')
            )}})])]),(_vm.roleSettings)?_c('tbody',_vm._l((_vm.roleSettings),function(roleSetting,key,index){return _c('tr',{key:`as_${index}`},[_c('td',{domProps:{"textContent":_vm._s(
              _vm.$t(
                `Components.Accounts.EditAccountRights.Label_${key.slice(
                  0,
                  -6
                )}`
              )
            )}}),_vm._l((4),function(i,idx){return _c('td',{key:`asg_${i}`,staticClass:"has-text-centered"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.roleSettings[key]),expression:"roleSettings[key]"}],attrs:{"type":"radio","disabled":_vm.editModeDisabled},domProps:{"value":_vm.rightStateLabels[idx],"checked":_vm._q(_vm.roleSettings[key],_vm.rightStateLabels[idx])},on:{"change":function($event){return _vm.$set(_vm.roleSettings, key, _vm.rightStateLabels[idx])}}})])})],2)}),0):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }